.app {
  height: 100%;
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: var(--primary-color);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  // font-family: "Kaushan Script", cursive;
  margin-bottom: 12px;
  font-family: "Oswald", sans-serif;
}
