.webdevelopment_page {
  // padding-top: 70px;
  background-color: #b3cceb;
}

.container1 {
  background-color: aquamarine;
}

.title_section {
  h1 {
    @media screen and (max-width: 900px) {
      padding-top: 60px;
    }
  }
}
