.footer {
  background-color: #88bdbc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: "fixed";
  left: 0;
  right: 0;
  bottom: 0;
}

.copyright-text {
  font-size: 12px;
  padding-left: 3px;
  @media screen and (max-width: 900px) {
    font-size: 10px;
  }
}
