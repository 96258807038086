.socials-link-home {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 20px;

  .social-icon {
    background-color: white;
  }

  @media screen and (max-width: 900px) {
    width: 340px;
  }
}
