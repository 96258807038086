// .about_page {
//   height: 100vh;
// }

.top_container {
  border-radius: 5px;
  background-color: white;
  @media screen and (max-width: 900px) {
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // padding: 10px;
    height: 100%;
    width: 100%;
  }
}

// .image_circle_about {
//   // width: 220px;
//   // height: 220px;
//   // background-color: gray;
//   // border-radius: 50%;
//   // border: solid black;
//   // overflow: hidden;
//   // margin-bottom: 10px;

//   @media screen and (max-width: 900px) {
//     width: 220px;
//     height: 220px;
//   }
// }

p {
  // justify-content: center;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.6;
  // color: #2b2b2b;
  // text-indent: 1em;
}
