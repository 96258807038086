.contact_page {
  background-color: #254e58;
  display: flex;
  //   border: 3px solid rgb(12, 115, 144);
  //   position: relative;
  //   overflow-y: auto;
  align-items: center;
  justify-content: center;
}

// .c-bg {
//   width: 20px;
//   height: 100%;
//   background-color: aqua;
//   position: relative;
// }

.c-wrapper {
  padding: 50px;
  display: flex;
  //   border: 3px solid green;
}

.c-left {
  flex: 1;
}
.c-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.c-title {
  font-size: 60px;
  width: 80%;
  color: white;
}

.c-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  width: 70%;
  color: white;
}

.c-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.c-desc {
  font-weight: 200;
  color: white;
}

form {
  margin-top: 20px;
}

input {
  width: 100%;
  height: 50px;
  border: none;
  // border-bottom: 2px solid #88bdbc;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}
input::placeholder {
  font-weight: bold;
  // opacity: 0.5;
  color: white;
}

textarea {
  padding: 15px;
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}
textarea::placeholder {
  font-weight: bold;
  // opacity: 0.5;
  color: white;
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: 25px;
  padding: 20px;
  background-color: #88bdbc;
  color: white;
  font-weight: 500;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;

  h3 {
    margin-right: 5px;
  }
}
/* template_vkg3ni8 */
/* service_fbbufg3 */

@media screen and (max-width: 480px) {
  .c-wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }

  .c-title {
    font-size: 30px;
    text-align: center;
  }

  .c-info-item {
    margin: 20px 0px;
    width: 100%;
  }

  .c-desc {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 100%;
    // height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    color: white;
    margin-top: 10px;
  }
}

.socials-link {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 20px;

  .social-icon {
    background-color: white;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}
